import { S25Util } from "../util/s25-util";

export type MemoOptions = {
    name?: string; // If omitted this will be a random ID
    // If provided, this function will be used to provide a unique identifier for the memo based on the arguments
    // Despite the name this does not need to be a string, but can be any object to be used as a key in a Map object.
    hasher?: (...args: any[]) => any[];
    useWeakMap?: boolean; // Normally a regular map is used. Set this flag to optionally use WeakMap
    ttl?: number; // Clear the cache entry after this many MS of not being accessed. If omitted, cache is kept indefinitely
    scopeToContext?: boolean; // If set to true, memo will be scoped to `this`
};

type MemoMap = { timeout: ReturnType<typeof setTimeout>; map: Map<any, unknown> | WeakMap<object, unknown> };

export class MemoRepository {
    public static _repository = new Map<string, MemoMap>();

    public static memoize(func: Function, options?: MemoOptions) {
        options ??= {};
        options.name ??= S25Util.generateQuickGUID();

        return function (...args: unknown[]) {
            // Get "hash"
            const hashed = options.hasher ? options.hasher(...args) : args;

            if (options.scopeToContext) hashed.push(this);

            // Check cache
            if (MemoRepository.has(options, hashed)) {
                return MemoRepository.get(options, hashed);
            }

            // Compute
            const result = func.apply(this, args);

            // Save
            MemoRepository.set(options, hashed, result);

            return result;
        };
    }

    public static has(options: MemoOptions, args: any[]) {
        const methodCache = MemoRepository._repository.get(options.name);
        if (!methodCache) return false;
        return MemoRepository.deepHas(methodCache, args);
    }

    public static get(options: MemoOptions, args: any[]) {
        const methodCache = MemoRepository._repository.get(options.name);
        if (!methodCache) return;
        return MemoRepository.deepGet(options, methodCache, args);
    }

    public static set(options: MemoOptions, args: any[], value: any) {
        const methodCache = MemoRepository.getMethodCacheUpsert(options);
        MemoRepository.deepSet(options, methodCache, args, value);
    }

    static getMethodCacheUpsert(options: MemoOptions) {
        if (!MemoRepository._repository.has(options.name)) {
            MemoRepository._repository.set(options.name, MemoRepository.createCache(options));
        }
        return MemoRepository._repository.get(options.name);
    }

    static deepHas(cache: MemoMap, args: any[]) {
        let data: any = cache;
        for (let arg of args) {
            if (data.map.has(arg)) data = data.map.get(arg);
            else return false;
        }
        return true;
    }

    static deepGet(options: MemoOptions, cache: MemoMap, args: any[]) {
        for (let i = 0; i < args.length - 1; i++) {
            const arg = args[i];
            if (!cache.map.has(arg)) return;
            MemoRepository.refreshTimeout(options, cache, arg);
            cache = cache.map.get(arg) as MemoMap;
        }
        return cache.map.get(args[args.length - 1]);
    }

    static deepSet(options: MemoOptions, cache: MemoMap, args: any[], value: any) {
        for (let i = 0; i < args.length - 1; i++) {
            const arg = args[i];
            if (!cache.map.has(arg)) cache.map.set(arg, MemoRepository.createCache(options, cache, arg));
            MemoRepository.refreshTimeout(options, cache, arg);
            cache = cache.map.get(arg) as MemoMap;
        }
        cache.map.set(args[args.length - 1], value);
    }

    static createCache(options: MemoOptions, parent?: MemoMap, arg?: any): MemoMap {
        return {
            timeout: parent ? MemoRepository.timeoutDelete(options, parent, arg) : null,
            map: options.useWeakMap ? new WeakMap<object, unknown>() : new Map<any, unknown>(),
        };
    }

    static refreshTimeout(options: MemoOptions, cache: MemoMap, arg: any) {
        const memo = cache.map.get(arg) as MemoMap;
        if (memo.timeout) clearTimeout(memo.timeout);
        memo.timeout = MemoRepository.timeoutDelete(options, cache, arg);
    }

    static timeoutDelete(options: MemoOptions, cache: MemoMap, arg: any) {
        if (!options.ttl) return null;
        return setTimeout(() => cache.map.delete(arg), options.ttl);
    }

    public static clearMethodCache(name: string) {
        return MemoRepository._repository.delete(name);
    }
}

export function Memo(options?: MemoOptions): MethodDecorator {
    return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void => {
        const originalMethod = descriptor.value;
        descriptor.value = MemoRepository.memoize(originalMethod, options);
    };
}

export function ClearMemo(name: string): MethodDecorator {
    return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void => {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args: unknown[]) {
            MemoRepository.clearMethodCache(name);
            return originalMethod.apply(this, args);
        };
    };
}
